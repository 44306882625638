<template>
	<div class="sms-modal">
		<img
			src="@/assets/icons/sms.svg"
			class="pl-10 mx-auto"
			height="170"
			width="140"
			alt="sms icon"
		>
		<h2 class="text-2xl font-semibold">
			Enter verification number
		</h2>
		<p class="pt-2">
			Please enter the 6 digit verification code sent to<br><strong>
				{{ formattedNumber }}
			</strong>
		</p>
		<div class="phone-input code">
			<input
				ref="digit1"
				v-model="digit[0]"
				step="1"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit1', 0)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit2"
				v-model="digit[1]"
				step="2"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit2', 1)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit3"
				v-model="digit[2]"
				step="3"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit3', 2)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit4"
				v-model="digit[3]"
				step="4"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit4', 3)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit5"
				v-model="digit[4]"
				step="5"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit5', 4)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit6"
				v-model="digit[5]"
				step="6"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit6', 5)"
				@keyup="checkDelete($event)"
			>
		</div>
		<button
			ref="submit"
			class="w-full px-3 py-2 font-semibold text-white rounded-md focus:ring focus:ring-green-300"
			:class="submitDisabled ? 'bg-gray-300 border-gray-300' : 'bg-green-500 border-green-500'"
			:disabled="submitDisabled"
			@click="sendCode"
		>
			Verify
		</button>
		<div class="mt-4 resend">
			<p>
				Didn't receive your code?<br><a
					class="text-green-500"
					href="#"
					@click="resendCode"
				>
					Resend Code
				</a>
			</p>
			<p>
				Wrong number?<br><a
					class="text-green-500"
					href="#"
					@click="resetNumber"
				>
					Reset Number
				</a>
			</p>
		</div>
	</div>
</template>

<script async>
import { mapMutations } from 'vuex'

import { ERROR } from '@/constants/toast/type.js'
import { logError } from '@/utils/error-handling.js'

export default {
	props: {
		phoneNumber: {
			type: String,
			default: ''
		},
		method: {
			type: String,
			default: ''
		},
		phoneNumberArray: {
			type: Array,
			default: () => []
		},
		focusOnInput: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'reset-number', 'final-step', 'focus-to-false' ],
	data() {
		return {
			digit: [
				'', // digit1
				'', // digit2
				'', // digit3
				'', // digit4
				'', // digit5
				'' // digit6
			],
			step: 0,
			shown: false,
			elementId: '',
			digitId: '',
			nextDigit: '',
			nextDigitId: '',
			previousDigit: '',
			previousDigitId: '',
			submitDisabled: false
		}
	},
	computed: {
		formattedNumber() {
			const areaCode = this.phoneNumber.substring(0, 3)
			const end = this.phoneNumber.substring(3, 12)
			const formatted = '(' + areaCode + ')' + end
			return formatted
		},
		currentInputValue() {
			return this.digit[this.digitId]
		}
	},
	watch: {
		digit: {
			handler: function() { // if this input value is not an empty string, use substring to trim any number passed the first number entered.
				if (this.digit[this.digitId] !== '') {
					const trimmedInput = this.digit[this.digitId].substring(0, 1)
					this.digit[this.digitId] = trimmedInput
					if (this.elementId !== 'digit6') {
						this.$refs[this.nextDigitId].focus()
					} else {
						this.$refs.submit.focus()
					}
				}
				this.sumbitReady()
			},
			deep: true
		},
		focusOnInput: {
			handler: function() {
				if (this.focusOnInput == true) {
					this.$refs.submit.focus()

					for (let index = 0; index < 6; index++) {
						if (this.digit[index] == undefined || this.digit[index] == '') {
							const newDigitId = 'digit' + (index + 1).toString()
							this.$refs[newDigitId].focus()
							break
						} else if (this.digit[index] !== undefined || this.digit[index] !== '') {
							continue
						}
					}
				}
			}
		}
	},
	mounted () {
		this.sumbitReady()
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		checkDelete(event) { // allows backspace and delete to move backwards though inputs
			if (this.currentInputValue == '') { // triggered by keyup
				if (event.key == 'Delete' || event.key == 'Backspace') {
					if (this.elementId !== 'digit1') {
						this.$refs[this.previousDigitId].focus()
					}
				}
			}
		},
		selectInput(event, inputId, arrayPosition) {
			event.target.select()
			this.setCurrentInput(inputId, arrayPosition)
		},
		setFocusToFalse() {
			this.$emit('focus-to-false')
		},
		setCurrentInput(inputId, arrayPosition) {
			this.elementId = inputId // this stores the active input's id so that we can shift focus on keyup based on what input we are using.
			this.digitId = arrayPosition // this stores the active input's position in the digit array so that we can reference it in the watcher to trim excess characters.
			this.nextDigit = this.digitId + 2
			this.previousDigit = this.digitId
			this.nextDigitId = 'digit' + this.nextDigit.toString()
			this.previousDigitId = 'digit' + this.previousDigit.toString()
		},
		sumbitReady() {
		// disable submit button if all inputs are not populated
			for (let index = 0; index < 6; index++) {
				this.submitDisabled = false
				if (this.digit[index] == undefined || this.digit[index] == '') {
					this.submitDisabled = true
					break
				} else if (this.digit[index] !== undefined || this.digit[index] !== '') {
					continue
				}
			}
		},
		sendCode: async function () {
			const postData = {
				type: 'Verification',
				func_name: 'checkVerificationCode',
				data: {
					code: this.digit,
					phone: this.phoneNumberArray
				}
			}
			try {
				const returnData = await this.$store.dispatch('apiCall', postData)
				if (returnData.data.success == true) {
					this.$emit('final-step', 2)
				}
			} catch (error) {
				logError(error)
			}
		},
		resendCode: async function () {
			const postData = {
				type: 'Verification',
				func_name: 'sendVerificationCode',
				data: {
					phone: this.phoneNumberArray,
					preferred_method: this.method
				}
			}
			try {
				await this.$store.dispatch('apiCall', postData)
			} catch (error) {
				this.showToast({
					primaryText: 'Invalid',
					secondaryText: error,
					type: ERROR
				})

				logError(error)
			}
		},
		resetNumber: async function () {
			this.$emit('reset-number')
		}
	}
}
</script>
